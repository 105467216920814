<template>
    <div>
        <label
            :for="`upload-common-${id}`"
            :class="`btn ${$props.classLabel}`"
            :style="{ cursor: disabled ? 'pointer' : 'not-allowed' }"
            :disabled="disabled"
            ><span><i class="fa fa-upload" aria-hidden="true"></i></span> {{ $props.title }}</label
        >
        <input v-if="disabled" type="file" :id="`upload-common-${id}`" ref="fileInput" hidden @change="handleFile" />
    </div>
</template>

<script>
import { campaignMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';
import { convertKBtoMB } from '@/utils/format';

export default {
    props: {
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: 'Upload a file'
        },
        classLabel: {
            type: String,
            default: 'btn-info'
        },
        disabled: {
            type: Boolean,
            default: true
        },
        typeUpload: {
            type: String,
            default: 'file'
        },
        processUpload: {
            type: Boolean,
            default: false
        },
        config: {
            type: Object,
            default: () => {
                {
                    return {
                        allowedSize: 1024,
                        allowedExtension: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp']
                    };
                }
            }
        }
    },
    data() {
        return {
            fileInfo: {
                originName: '',
                extension: '',
                fileSize: 0
            },
            configUpload: this.config
        };
    },

    methods: {
        ...campaignMethods,

        handleFile() {
            const fileInput = this.$refs.fileInput;
            if (this.typeUpload == 'file') {
                this.displayFileInfoFile(fileInput);
            } else if (this.typeUpload == 'image') {
                this.displayFileInfoImage(fileInput);
            }
        },

        displayFileInfoFile(receivedFile) {
            if (receivedFile.files.length > 0) {
                const file = receivedFile.files[0];
                this.fileInfo.originName = file.name;
                this.fileInfo.extension = file.name.split('.').pop(); // Lấy phần mở rộng của tệp
                this.fileInfo.type = file.type; // Lấy phần mở rộng của tệp
                this.fileInfo.fileSize = (file.size / 1024).toFixed(2); // Chuyển đổi size sang KB và làm tròn đến 2 chữ số thập phân

                if (this.configUpload?.allowedExtension?.indexOf(this.fileInfo.type) <= -1) {
                    showMessage(8080, this.$bvToast, this.$t('uploadFile.format'));
                    return;
                }

                if (this.fileInfo.fileSize >= this.configUpload.allowedSize && !this.configUpload?.maxSize) {
                    showMessage(8080, this.$bvToast, this.$t('uploadFile.maxSize1MB'));
                    return;
                }

                if (this.fileInfo.fileSize >= this.configUpload?.maxSize) {
                    showMessage(
                        8080,
                        this.$bvToast,
                        this.$t('uploadFile.maxSizeCustom', {
                            size: convertKBtoMB(this.configUpload?.maxSize)
                        })
                    );
                    return;
                }

                let query = `origin_name=${this.fileInfo.originName}&extension=${this.fileInfo.extension}&file_size=${parseInt(this.fileInfo.fileSize)}&type=${this.fileInfo.type}`;
                this.$emit('update:processUpload', true);
                this.uploadFile(query).then((data) => {
                    if (data.code == 200) {
                        data.data.origin_name = this.fileInfo.originName;
                        data.data.extension = this.fileInfo.extension;
                        data.data.fileSize = this.fileInfo.fileSize;
                        this.$emit('dataFile', data.data);
                        receivedFile.value = '';
                        const formData = new FormData();
                        formData.append('file', file);
                        var requestOptions = {
                            method: 'PUT',
                            body: file
                        };
                        fetch(data.data.url, requestOptions)
                            .then((response) => response.text())
                            .then()
                            .catch((error) => console.log('error', error));
                    }
                    this.$emit('update:processUpload', false);
                });
            }
        },

        displayFileInfoImage(receivedFile) {
            if (!receivedFile.files.length) return;
            const file = receivedFile.files[0];
            this.fileInfo.originName = file.name;
            this.fileInfo.extension = file.name.split('.').pop(); // Lấy phần mở rộng của tệp
            this.fileInfo.type = file.type; // Lấy phần mở rộng của tệp
            this.fileInfo.fileSize = (file.size / 1024).toFixed(2); // Chuyển đổi size sang KB và làm tròn đến 2 chữ số thập phân
            if (this.configUpload?.allowedExtension?.indexOf(this.fileInfo.type) <= -1) {
                showMessage(8080, this.$bvToast, this.$t('uploadFile.format'));
                return;
            }

            if (this.fileInfo.fileSize >= this.configUpload.allowedSize && !this.configUpload?.maxSize) {
                showMessage(8080, this.$bvToast, this.$t('uploadFile.maxSize1MB'));
                return;
            }

            if (this.fileInfo.fileSize >= this.configUpload?.maxSize) {
                showMessage(
                    8080,
                    this.$bvToast,
                    this.$t('uploadFile.maxSizeCustom', {
                        size: convertKBtoMB(this.configUpload?.maxSize)
                    })
                );
                return;
            }
            let query = `origin_name=${this.fileInfo.originName}&extension=${
                this.fileInfo.extension
            }&file_size=${parseInt(this.fileInfo.fileSize)}&type=${this.fileInfo.type}`;
            this.$emit('update:processUpload', true);
            this.uploadFile(query).then((data) => {
                if (data.code == 200) {
                    data.data.origin_name = this.fileInfo.originName;
                    data.data.extension = this.fileInfo.extension;
                    data.data.fileSize = this.fileInfo.fileSize;
                    this.$emit('dataFile', data.data);
                    receivedFile.value = '';
                    const formData = new FormData();
                    formData.append('file', file);
                    var requestOptions = {
                        method: 'PUT',
                        body: file
                    };
                    fetch(data.data.url, requestOptions)
                        .then((response) => response.text())
                        .then()
                        .catch((error) => console.log('error', error));
                }
                this.$emit('update:processUpload', false);
            });
        }
    },

    watch: {
        config: {
            handler: function (newConfig) {
                this.configUpload = newConfig;
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped>
label {
    display: inline-block;
    color: white;
    cursor: pointer;
}
</style>
