<template>
    <div>
        <div class="d-flex flex-wrap" style="gap: 10px">
            <div class="d-flex align-items-center flex-nowrap" :id="config.id">
                <input
                    type="text"
                    v-model="dateYear"
                    :maxlength="4"
                    style="width: 80px; padding-right: 0 !important"
                    class="form-control mr-2"
                    :class="{
                        'is-invalid': config?.error,
                        disabled_cursor: disabled
                    }"
                    :disabled="disabled"
                    @keypress="inputYear($event)"
                    @blur="handleBlur"
                />
                {{ this.$t('text.year') }}
                <input
                    type="text"
                    v-model="dateMonth"
                    :maxlength="2"
                    style="width: 47px; padding-right: 0 !important"
                    class="form-control mx-2"
                    :class="{
                        'is-invalid': config?.error,
                        disabled_cursor: disabled
                    }"
                    :disabled="disabled"
                    @keypress="inputMonth($event)"
                    @blur="handleBlur"
                    @input="limitMonthValue"
                />
                {{ this.$t('text.month') }}
                <input
                    type="text"
                    v-model="dateDay"
                    :maxlength="2"
                    style="width: 47px; padding-right: 0 !important"
                    class="form-control mx-2"
                    :class="{
                        'is-invalid': config?.error,
                        disabled_cursor: disabled
                    }"
                    :disabled="disabled"
                    @keypress="inputDay($event)"
                    @blur="handleBlur"
                />
                {{ this.$t('text.day') }}
                <button
                    class="btn-sm btn btn-info mx-2 box-date"
                    type="button"
                    style="min-width: fit-content"
                    :disabled="disabled"
                    :class="{
                        disabled_cursor: disabled
                    }"
                >
                    {{ this.$t('btn.selectCalendar') }}
                    <DatePicker
                        ref="scheduled_end_date"
                        v-model="fullDate"
                        :value-type="customFormatDate"
                        :first-day-of-week="1"
                        lang="ja"
                        :disabled="disabled"
                    ></DatePicker>
                </button>
            </div>
            <div class="" :class="config.error ? 'time__task--error' : ''" style="max-width: 150px">
                <DatePicker
                    ref="scheduled_time"
                    v-model="fullTime"
                    format="HH:mm"
                    value-type="format"
                    type="time"
                    :class="disabled ? 'custom__date-time--disabled' : ''"
                    :minute-step="config.minuteStep ?? 1"
                    :hour-options="hours"
                    :disabled="disabled"
                    :placeholder="config.placeholderTime ?? ''"
                    :open.sync="isOpen"
                    @change="handleChangeFrom"
                    @open="handleOpenPicker"
                    @close="handleClosePicker"
                ></DatePicker>
            </div>
        </div>
        <slot />
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { isNumber, STR_DATE } from '@/utils/format';

export default {
    name: 'DateTime',
    components: {
        DatePicker
    },
    props: {
        id: {
            type: String,
            required: false,
            default: 'select-date-time'
        },
        year: {
            type: String,
            required: false,
            default: ''
        },
        month: {
            type: String,
            required: false,
            default: ''
        },
        day: {
            type: String,
            required: false,
            default: ''
        },
        full_date: {
            type: null,
            required: true,
            default: null
        },
        full_time: {
            type: null,
            required: true,
            default: null
        },
        config: {
            type: Object,
            required: true,
            default() {
                return {
                    minuteStep: 1,
                    placeholderTime: '',
                    valueSync: '',
                    error: false
                };
            }
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            date: this.value,
            customFormatDate: STR_DATE,
            dateYear: '',
            dateMonth: '',
            dateDay: '',
            fullDate: '',
            fullTime: '',
            hours: Array.from({ length: 24 }).map((_, i) => i + 0),
            isOpen: false
        };
    },
    watch: {
        '$props.year': {
            handler: function (newYear) {
                this.dateYear = newYear;
            },
            immediate: true
        },
        '$props.month': {
            handler: function (newMonth) {
                this.dateMonth = newMonth;
            },
            immediate: true
        },
        '$props.day': {
            handler: function (newDay) {
                this.dateDay = newDay;
            },
            immediate: true
        },
        '$props.full_date': {
            handler: function (newFullDate) {
                this.fullDate = newFullDate ?? '';
                this.updateDate(this.fullDate);
            },
            immediate: true
        },
        '$props.full_time': {
            handler: function (newFullTime) {
                this.fullTime = newFullTime ?? '';
                this.updateTime(this.fullTime);
            },
            immediate: true
        },
        '$props.config': {
            handler: function (newConfig) {
                this.config = newConfig;
                this.$emit('update:config', this.config);
            },
            immediate: true,
            deep: true
        },
        fullTime: {
            handler: function (newFullTime) {
                this.fullTime = newFullTime ?? '';
                this.updateTime(this.fullTime);
                this.$emit('change', `${this.fullTime ? `${this.fullDate} ${this.fullTime}` : this.fullDate}`);
            }
        },
        fullDate: {
            handler: function (newFullDate) {
                this.fullDate = newFullDate ?? '';
                this.updateDate(this.fullDate);
                this.$emit('change', `${this.fullTime ? `${this.fullDate} ${this.fullTime}` : this.fullDate}`);
            }
        },
        dateYear: {
            handler: function (newYear) {
                this.$emit('update:year', newYear);
                this.handleInput();
            }
        },
        dateMonth: {
            handler: function (newMonth) {
                this.$emit('update:month', newMonth);
                this.handleInput();
            }
        },
        dateDay: {
            handler: function (newDay) {
                this.$emit('update:day', newDay);
                this.handleInput();
            }
        }
    },
    methods: {
        updateDate(newFullDate) {
            if (!newFullDate) {
                this.dateYear = '';
                this.dateMonth = '';
                this.dateDay = '';
                this.fullTime = '';
            } else {
                const date = newFullDate.split('-');
                this.dateYear = date[0];
                this.dateMonth = date[1];
                this.dateDay = date[2];
            }
            let config = this.config;
            if (this.fullTime) config.error = false;
            config.valueSync = this.fullTime ? `${newFullDate} ${this.fullTime}` : newFullDate ?? '';

            this.$emit('update:config', config);
            this.$emit('update:year', this.dateYear);
            this.$emit('update:month', this.dateMonth);
            this.$emit('update:day', this.dateDay);
            this.$emit('update:full_date', this.fullDate);
        },
        updateTime(newFullTime) {
            let config = this.config;
            config.error = false;
            config.valueSync = newFullTime ? `${this.fullDate} ${newFullTime}` : this.fullDate ?? '';
            this.fullTime = !this.fullDate ? '' : newFullTime;

            this.$emit('update:config', config);
            this.$emit('update:full_time', this.fullTime);
        },
        handleInput() {
            if (!this.dateYear && !this.dateMonth && !this.dateDay) {
                this.fullDate = '';
                return;
            }
            this.fullDate = `${this.dateYear}-${this.dateMonth}-${this.dateDay}`;
        },
        inputYear(event) {
            isNumber(event);
        },
        inputMonth(event) {
            isNumber(event);
        },
        limitMonthValue() {
            if (this.dateMonth > 12) {
                this.dateMonth = '';
            }
        },
        inputDay(event) {
            isNumber(event);
        },
        handleChangeFrom(value, type) {
            if (type === 'minute') {
                let config = this.config;
                config.error = false;
                this.isOpen = false;

                this.$emit('update:config', config);
            }
            this.$emit('change');
        },

        handleBlur() {
            this.$emit('blur');
        },

        handleOpenPicker() {
            this.$emit('open');
        },

        handleClosePicker() {
            this.$emit('close');
        }
    }
};
</script>

<style lang="scss">
.time__task--error {
    .mx-input-wrapper {
        border: 1px solid #ff3d60;
        border-radius: 5px;
    }
}
.custom__date-time--disabled {
    .mx-input-wrapper {
        .mx-input:disabled {
            color: #505d69;
        }
    }
}
.disabled_cursor {
    .mx-datepicker {
        .mx-input-wrapper {
            input.mx-input {
                cursor: not-allowed;
            }
        }
    }
}
</style>
